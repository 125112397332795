<template>
  <tp-modal name="modal-cancel" max-width="480px">
    <v-card slot-scope="props">
      <v-card-title v-html="props.payload.title"> </v-card-title>

      <v-card-text class="text-body-1">
        <div>
          <template v-if="props.payload.description">
            {{ props.payload.description }}
          </template>
          <template v-else>
            Hành động này sẽ không thể hoàn tác. Nhập lý do hủy vào ô bên dưới
            nếu bạn chắc chắn muốn tiếp tục:
          </template>
        </div>
        <v-textarea
          v-model="cancelReason"
          class="text-body-1 mt-2"
          auto-grow
          hide-details
          placeholder="Nhập lý do hủy tại đây"
          outlined
          rows="3"
          single-line
        ></v-textarea>
      </v-card-text>

      <v-card-actions class="px-5 pb-4 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          text
          color="grey darken-1"
          @click="
            closeModal(
              props.payload.onCloseHandler
                ? props.payload.onCloseHandler
                : undefined
            )
          "
        >
          Bỏ qua
        </v-btn>
        <v-btn
          text
          color="red accent-2"
          :disabled="!cancelReason || cancelReason.trim() === ''"
          @click="
            onSubmit(
              props.payload.onSubmitHandler
                ? props.payload.onSubmitHandler
                : undefined
            )
          "
        >
          Tiếp tục hủy
        </v-btn>
      </v-card-actions>
    </v-card>
  </tp-modal>
</template>

<script>
export default {
  data() {
    return {
      cancelReason: null
    };
  },
  methods: {
    async onSubmit(onSubmitHandler) {
      this.$modal.hide({ name: "modal-cancel" });

      if (typeof onSubmitHandler === "function") {
        onSubmitHandler(this.cancelReason);
      }
      this.cancelReason = null;
    },
    closeModal(onCloseHandler) {
      this.$modal.hide({ name: "modal-cancel" });

      if (typeof onCloseHandler === "function") {
        onCloseHandler();
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
