var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('tp-modal',{attrs:{"name":"modal-cancel","max-width":"480px"},scopedSlots:_vm._u([{key:"default",fn:function(props){return _c('v-card',{},[_c('v-card-title',{domProps:{"innerHTML":_vm._s(props.payload.title)}}),_c('v-card-text',{staticClass:"text-body-1"},[_c('div',[(props.payload.description)?[_vm._v(" "+_vm._s(props.payload.description)+" ")]:[_vm._v(" Hành động này sẽ không thể hoàn tác. Nhập lý do hủy vào ô bên dưới nếu bạn chắc chắn muốn tiếp tục: ")]],2),_c('v-textarea',{staticClass:"text-body-1 mt-2",attrs:{"auto-grow":"","hide-details":"","placeholder":"Nhập lý do hủy tại đây","outlined":"","rows":"3","single-line":""},model:{value:(_vm.cancelReason),callback:function ($$v) {_vm.cancelReason=$$v},expression:"cancelReason"}})],1),_c('v-card-actions',{staticClass:"px-5 pb-4 pt-0"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"grey darken-1"},on:{"click":function($event){return _vm.closeModal(
            props.payload.onCloseHandler
              ? props.payload.onCloseHandler
              : undefined
          )}}},[_vm._v(" Bỏ qua ")]),_c('v-btn',{attrs:{"text":"","color":"red accent-2","disabled":!_vm.cancelReason || _vm.cancelReason.trim() === ''},on:{"click":function($event){return _vm.onSubmit(
            props.payload.onSubmitHandler
              ? props.payload.onSubmitHandler
              : undefined
          )}}},[_vm._v(" Tiếp tục hủy ")])],1)],1)}}])})}
var staticRenderFns = []

export { render, staticRenderFns }